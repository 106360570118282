function renderModel(env) {
    if (env.three.status === 'model') {
        return;
    }
    for (let flowerObject of env.objects.flowers) {
        let val = true;
        if (env.params.showId) {
            val = false;
            if (env.params.showId === flowerObject.id) {
                val = true;
            }
        }
        flowerObject.clearReal(val);
    }
    env.three.status = 'model'
}

export default renderModel;