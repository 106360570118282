<template>
  <div class="add">
    <template v-if="!status">
      <div class="avatar">
        <van-uploader
          :before-read="beforeRead"
          :after-read="afterRead"
          v-model="fileList"
          :max-count="1"
          class="photo"
          :before-delete="afterDelete"
        >
          <div class="avatar_main"></div>
        </van-uploader>
        <div>上传照片</div>
      </div>
      <van-cell-group>
        <van-field
          v-model="queryParams.studentName"
          placeholder="请填写姓名"
          label="姓名"
          maxlength="30"
          label-class="label-justify"
        />
      </van-cell-group>
      <van-form>
        <van-cell-group>
          <van-field
            v-model="queryParams.mobile"
            placeholder="请填写手机号"
            label="手 机 号"
            :border="true"
            type="number"
            maxlength="11"
            :rules="
              queryParams.mobile == ''
                ? []
                : [{ pattern: /^1[3456789]\d{9}$/, message: '格式错误！' }]
            "
          >
            <template #button>
              <van-button size="small" type="primary" @click="sendCode">{{
                cutdown ? cutdown + "s" : "获取验证码"
              }}</van-button>
            </template>
          </van-field>
          <van-field
            v-model="queryParams.smsCode"
            placeholder="请填写手机验证码"
            label="验 证 码"
            :border="true"
            type="number"
            maxlength="4"
          />
        </van-cell-group>
      </van-form>
      <div @click="submit" class="submit_btn">提交审核</div>
    </template>
    <template v-else>
      <div class="success">
        <div>
          <van-icon name="success" />
        </div>
        <div>提交成功</div>
        <div>审核后即可开始数字插花</div>
      </div>
    </template>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      cutdown: 0,
      send: false,
      organizationId: "",
      fileList: [],
      queryParams: {
        smsCode: "",
        studentName: "",
        studentNumber: new Date().getTime(),
        mobile: "",
        shareCode: "",
        avatar: "",
      },
      postData: [],
      status: false,
      ossData: {},
      upCount: 0,
    };
  },
  methods: {
    sendCode() {
      if (!/^1\d{10}$/.test(this.queryParams.mobile)) {
        Toast("请输入正确的手机号");
        return;
      }
      this.send = true;
      if (this.cutdown) {
        return;
      }
      this.cutdown = 60;
      this.$ajax.post("/login/sms/send", {
        phone: this.queryParams.mobile,
        smsType: "REGISTER",
      });
      let tId = setInterval(() => {
        this.cutdown--;
        if (!this.cutdown) {
          clearInterval(tId);
        }
      }, 1000);
    },
    uploadToOSS(file, ossUploadKey) {
      return new Promise((resolve, reject) => {
        console.log(file, ossUploadKey);
        let formData = new FormData();
        let relativeURL =
          ossUploadKey.path +
          ossUploadKey.fileName +
          +new Date().getTime() +
          (Math.floor(Math.random() * 10) + 1) +
          file.type.replace("image/", ".");
        formData.append("key", relativeURL); //存储在oss的文件路径
        formData.append("OSSAccessKeyId", ossUploadKey.accessid); //accessKeyId
        formData.append("policy", ossUploadKey.policy); //policy
        formData.append("Signature", ossUploadKey.signature); //签名//如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
        formData.append("file", file);
        formData.append("success_action_status", 200);
        this.$axios.post(ossUploadKey.host, formData).then((res) => {
          resolve(relativeURL);
        });
      });
    },
    beforeRead(file) {
      if (
        file.type !== "image/jpeg" &&
        file.type !== "image/png" &&
        file.type !== "image/jpg"
      ) {
        Toast("图片只能是 jpg/png 格式!");
        return false;
      }
      if (file.size / 1024 / 1024 > 5) {
        Toast("图片大小不能超过 5MB!");
        return false;
      }
      return true;
    },
    afterRead(file) {
      this.uploadToOSS(file.file, this.ossData).then((url) => {
        this.upCount = 0;
        this.queryParams.avatar = url;
      });
    },
    async afterDelete(file) {
      this.fileList = [];
      this.this.queryParams.avatar = "";
    },
    submitTeacher(organizationId) {
      if (this.fileList.length == 0) {
        Toast("照片不能为空");
        return;
      }
      if (!this.queryParams.studentName) {
        Toast("姓名不能为空");
        return;
      }
      if (!this.queryParams.mobile) {
        Toast("手机号不能为空");
        return;
      }
      this.$ajax
        .post("/user/organization/admin/teacher/add", {
          smsCode: this.queryParams.smsCode,
          shareCode: this.queryParams.shareCode,
          avatar: this.queryParams.avatar,
          idCard: "350182199601052921",
          contactsPhone: this.queryParams.mobile,
          position: "老师",
          gender: 1,
          name: this.queryParams.studentName,
          organizationId,
        })
        .then((res) => {
          if (res.code == 200) {
            Toast.success("操作成功");
            this.status = true;
          }
        });
    },
    submit() {
      if (!this.send) {
        Toast("请先发送验证码");
        return;
      }
      if (this.organizationId) {
        this.submitTeacher(this.organizationId);
        return;
      }
      let self = this;
      if (this.fileList.length == 0) {
        Toast("照片不能为空");
        return;
      }
      if (!self.queryParams.studentName) {
        Toast("姓名不能为空");
        return;
      }
      if (!self.queryParams.studentNumber) {
        Toast("学号不能为空");
        return;
      }
      if (!self.queryParams.mobile) {
        Toast("手机号不能为空");
        return;
      }
      // self.queryParams.avatar = this.fileList;
      let ajaxData = {
        smsCode: self.queryParams.smsCode,
        shareCode: self.queryParams.shareCode,
        studentName: self.queryParams.studentName,
        studentNumber: self.queryParams.studentNumber,
        phoneNumber: self.queryParams.mobile,
        avatar: self.queryParams.avatar,
      };
      this.$ajax.post("/user/examine/submit", ajaxData).then((res) => {
        Toast.success("操作成功");
        this.status = true;
      });
    },
    getOssKey() {
      return this.$ajax.post("user/oss/getKey?pathName=avatar");
    },
  },
  async mounted() {
    this.organizationId = this.$route.query.organization;
    this.queryParams.shareCode = this.$route.query.code;
    document.title = "提交审核";
    let ossData = await this.getOssKey();
    this.ossData = ossData.data;
  },
};
</script>

<style lang="scss" scoped>
.success {
  > div {
    &:nth-child(1) {
      width: 25vw;
      line-height: 25vw;
      text-align: center;
      margin: 0 auto;
      font-size: 15vw;
      background: #6e9f21;
      border-radius: 100%;
      color: #fff;
    }
    &:nth-child(2) {
      margin-top: 7vw;
      text-align: center;
      font-size: 8vw;
    }
    &:nth-child(3) {
      margin-top: 3vw;
      text-align: center;
      font-size: 5vw;
    }
  }
}
.submit_btn {
  position: fixed;
  width: 95vw;
  border-radius: 1vw;
  line-height: 12vw;
  bottom: 1vw;
  background: #6e9f21;
  color: #fff;
  text-align: center;
  left: 2.5vw;
}
.add {
  padding-top: 15vw;
}
.avatar {
  text-align: center;
  .avatar_main {
    width: 40vw;
    height: 40vw;
    margin: 0 auto;
    border-radius: 3vw;
    border: 1px solid #cecece;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
  > div {
    &:last-child {
      margin-top: 3vw;
      color: #999;
      margin-bottom: 6vw;
    }
  }
}

::v-deep .van-uploader__preview-image {
  width: 40vw;
  height: 40vw;
}
::v-deep .van-uploader__preview-delete {
  height: 5vw;
  width: 5vw;
  border-radius: 0 0 0 3vw;

  .van-icon-cross {
    font-size: 6vw;
  }
}
</style>
