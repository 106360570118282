import renderReal from "../../render/renderReal";
class RotateCommand {
    constructor(params, env) {
        this.params = params;
        this.env = env
    }
    do() {
        let faceIndex = 1;
        for (let flowerObject of this.env.objects.flowers) {
            if (this.params.id === flowerObject.id) {
                flowerObject.clearReal();
                faceIndex = flowerObject.rotate(this.params.val);
                break;
            }
        }
        renderReal(this.env);
        return faceIndex;
    }
    prev() {

    }
}

export default RotateCommand;