class CreateImageCommand {
    constructor(params, env) {
        this.width = params.width;
        this.height = params.height;
        this.preview = env.three.renderer.domElement;
    }
    do() {
        let preCanvas = document.createElement('canvas'),
            preCtx = preCanvas.getContext('2d');
        preCanvas.width = this.preview.width;
        preCanvas.height = this.preview.height;
        preCtx.drawImage(this.preview, 0, 0);

        //寻找图像边界
        let minx = -1,
            miny = -1,
            maxx = -1,
            maxy = -1,
            imgData = preCtx.getImageData(
                0,
                0,
                preCanvas.width,
                preCanvas.height
            ),
            preInfo = {};
        for (let y = 0; y < preCanvas.height; y++) {
            for (let x = 0; x < preCanvas.width; x++) {
                let i = y * preCanvas.width + x;
                if (imgData.data[4 * i + 3]) {
                    if (minx + miny + maxx + maxy === -4) {
                        minx = x;
                        miny = y;
                        maxx = x;
                        maxy = y;
                    }

                    if (x < minx) minx = x;
                    if (y < miny) miny = y;
                    if (x > maxx) maxx = x;
                    if (y > maxy) maxy = y;
                    imgData.data[4 * i] = 255 - imgData.data[4 * i];
                    imgData.data[4 * i + 1] = 255 - imgData.data[4 * i + 1];
                    imgData.data[4 * i + 2] = 255 - imgData.data[4 * i + 2];
                }
            }
        }
        minx -= 50;
        maxx += 50;
        miny -= 50;
        maxy += 50;

        minx = minx < 0 ? 0 : minx;
        maxx = maxx > preCanvas.width ? preCanvas.width : maxx;
        miny = miny < 0 ? 0 : miny;
        maxy = maxy > preCanvas.height ? preCanvas.height : maxy;

        preInfo = { x: minx, y: miny, width: maxx - minx, height: maxy - miny };
        //根据图像边界绘制图片
        let resCanvas = document.createElement('canvas'),
            resCtx = resCanvas.getContext('2d'),
            rImg = preInfo.width / preInfo.height,
            drawWidth,
            drawHeight;
        resCanvas.width = this.width;
        resCanvas.height = this.height;
        if (rImg < this.width / this.height) {
            drawWidth = this.height * rImg;
            drawHeight = this.height;
        } else {
            drawWidth = this.width;
            drawHeight = this.width / rImg;
        }
        resCtx.drawImage(
            preCanvas,
            preInfo.x,
            preInfo.y,
            preInfo.width,
            preInfo.height,
            this.width / 2 - drawWidth / 2,
            this.height / 2 - drawHeight / 2,
            drawWidth,
            drawHeight
        );

        return resCanvas.toDataURL();
    }
}

export default CreateImageCommand;