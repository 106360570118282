<template>
  <div class="act_layout">
    <div class="lv_flexalign_center">
      <div style="margin-right: 5px">单枝</div>
      <van-slider
        class="lv_flex_1"
        v-model="bindingY"
        @input="handleInput"
        @change="renderRealScene"
        bar-height="4px"
        inactive-color="#999"
        active-color="#6e9f21"
        :min="-200"
        :max="200"
      />
      <div class="slider_text">{{ bindingY }}</div>
    </div>

    <div class="lv_flexalign_center" style="margin-top: 15px">
      <div style="margin-right: 5px">整束</div>
      <van-slider
        class="lv_flex_1"
        v-model="bindingAllPoint"
        @input="handleAllInput"
        @change="renderRealScene"
        bar-height="4px"
        inactive-color="#999"
        active-color="#6e9f21"
        :min="-200"
        :max="200"
      />
      <div class="slider_text">{{ bindingAllPoint }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bindingY: 0,
      bindingAllPoint: 0,
      id: null,
    };
  },
  methods: {
    init(item) {
      this.id = item.id;
      this.bindingY = item.bindingY;
      this.bindingAllPoint = item.bindingY;
    },
    handleAllInput() {
      this.$bus.$emit("setBindingY", {
        isAll: true,
        value: this.bindingAllPoint,
      });
    },
    handleInput() {
      if (!this.id) {
        return;
      }
      this.$bus.$emit("setBindingY", {
        id: this.id,
        value: this.bindingY,
      });
    },
    renderRealScene() {
      this.$bus.$emit("renderRealScene");
    },
  },
};
</script>

<style>
</style>