<template>
  <div class="lv_flexalign_center act_layout">
    <div
      class="act_button"
      :class="{ actived: actived === '' }"
      @click="
        actived = '';
        change();
      "
    >
      1
    </div>
    <div
      class="act_button"
      :class="{ actived: actived === 2 }"
      @click="
        actived = 2;
        change();
      "
    >
      2
    </div>
    <div
      class="act_button"
      :class="{ actived: actived === 3 }"
      @click="
        actived = 3;
        change();
      "
    >
      3
    </div>
    <div
      class="act_button"
      :class="{ actived: actived === 4 }"
      @click="
        actived = 4;
        change();
      "
    >
      4
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      actived: "",
    };
  },
  methods: {
    change() {
      this.$bus.$emit(
        "setBackground",
        this.actived ? `./${this.actived}.jpg` : ""
      );
    },
  },
};
</script>

<style>
</style>