import AddItemCommand from "./AddItemCommand";
class ReomveCommand {
    constructor(id, env) {
        this.id = id;
        this.env = env;
        this.parmas = null;
        this.containChange=true;
    }
    do() {
        let containerObject = this.env.objects.container;
        if (containerObject && (containerObject.id === this.id)) {
            this.parmas = {
                id: this.id,
                resourceId: containerObject.resource.id,
                params: containerObject.params
            }
            containerObject.remove();
        } else {
            for (let flowerObject of this.env.objects.flowers) {
                if (this.id === flowerObject.id) {
                    this.parmas = {
                        id: this.id,
                        resourceId: flowerObject.resource.id,
                        position: flowerObject.modelMesh.flowerMesh.position,
                        params: flowerObject.params
                    }
                    flowerObject.remove();
                    break;
                }
            }
        }
    }
    async prev() {
        new AddItemCommand(this.parmas, this.env).do();
    }
}

export default ReomveCommand;