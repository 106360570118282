
class setAllBindingYByShiftingCommand {
    constructor(params, env) {
        this.params = params;
        this.env = env
    }
    do() {
        for (let flowerObject of this.env.objects.flowers) {
            flowerObject.clearReal();
            flowerObject.params.bindingY = this.params.value;
            flowerObject.updateModel();
        }
    }
}

export default setAllBindingYByShiftingCommand;