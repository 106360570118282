<template>
  <router-view></router-view>
</template>
<script>
export default {
  created(){
        this.$store.commit('initParams',this.$route.query);
  }
}
</script>
