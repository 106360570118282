
import {
    CAMERA_RADIUS,
    CAMERA_LOOKAT_Y
} from '../../variable/constant';
import renderReal from '../../render/renderReal';
import renderModel from "../../render/renderModel";
class SetAngleCommand {
    constructor(params, env) {
        this.params = params;
        this.env = env
    }
    do() {
        return new Promise((resolve) => {
            const newCameraPosition = new THREE.Vector3(0, 0, CAMERA_RADIUS).applyEuler(
                new THREE.Euler(
                    THREE.MathUtils.degToRad(this.params.x - 90),
                    THREE.MathUtils.degToRad(this.params.y),
                    0,
                    'YXZ'
                ));
            this.env.three.viewController.object.position.set(newCameraPosition.x, newCameraPosition.y + CAMERA_LOOKAT_Y, newCameraPosition.z);
            setTimeout(() => {
                renderReal(this.env);
                resolve('finish')
            }, 100);
        })

    }
}

export default SetAngleCommand;