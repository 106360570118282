const CAMERA_LOOKAT_Y = 200; //摄像机瞄准点高度
const CAMERA_RADIUS = 700; //摄像机半径
const BINDING_DEFAULT_Y = 200; //默认扎点
const REAL_TO_EDITOR_RATE = 3;  //现实到编辑器中的比例
const INIT_Y = BINDING_DEFAULT_Y + 50; //默认插入高度
export {
    CAMERA_LOOKAT_Y,
    CAMERA_RADIUS,
    BINDING_DEFAULT_Y,
    REAL_TO_EDITOR_RATE,
    INIT_Y
}