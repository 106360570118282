<template>
  <div class="lv_flexalign_center act_layout">
    <div class="act_button" @click="change(25)">+</div>
    <div class="act_button" @click="change(-25)">-</div>
    <div class="percent">{{ sliderValue }}%</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sliderValue: 100,
    };
  },
  methods: {
    change(val) {
      if (this.sliderValue + val === 0 || this.sliderValue + val === 225) {
        return;
      }
      this.sliderValue += val;
      this.$bus.$emit("set-zoom", this.sliderValue / 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.percent {
  width: rem(150);
  text-align: center;
}
</style>