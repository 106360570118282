import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    routeParams: {
      type: 'practice',
      mode: 'edit',
      text: '练习',
      id: null,
      productId: null,
    },
    limitMaterials: []
  },
  mutations: {
    initParams(state, query) {
      state.routeParams.type = query.type || 'practice';
      state.routeParams.text = query.type === 'practice ' ? '练习' : query.type === 'schoolwork' ? '作业' : '比赛';
      state.routeParams.mode = query.mode || 'edit';
      state.routeParams.id = query.id || null;
      state.routeParams.productId = query.productId || null;
    },
    initLimitMaterials(state, query) {
      state.limitMaterials = query
    }
  }
})
export default store