<template>
  <div v-if="loaded">
    <topController />
    <mainController />
    <editor />
  </div>
</template>

<script>
import topController from "./components/topController.vue";
import mainController from "./components/mainController.vue";
import editor from "./components/editor.vue";
import { login } from "@/plugins/utils.js";
export default {
  components: {
    topController,
    mainController,
    editor,
  },
  data() {
    return {
      loaded: false,
    };
  },
  created() {
    login(() => {
      this.loaded = true;
    }, this);
  },
};
</script>

<style>
</style>