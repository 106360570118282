<template>
  <div class="lv_flexalign_center act_layout">
    <div
      class="act_button"
      :class="{ actived: actived === 1 }"
      @click="
        actived = 1;
        change(true);
      "
    >
      锁定
    </div>
    <div
      class="act_button"
      :class="{ actived: actived === 2 }"
      @click="
        actived = 2;
        change(false);
      "
    >
      解锁
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      actived: 2,
    };
  },
  methods: {
    change(val) {
      this.$bus.$emit("canvasLock", val);
    },
  },
};
</script>

<style>
</style>