<template>
  <div class="lv_flexalign_center act_layout">
    <div
      class="act_button"
      :class="{ actived: actived === 1 }"
      @click="
        actived = 1;
        change('chineseStyle');
      "
    >
      中式
    </div>
    <div
      class="act_button"
      :class="{ actived: actived === 2 }"
      @click="
        actived = 2;
        change('westStyle');
      "
    >
      西式螺旋
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      actived: 1,
    };
  },
  methods: {
    change(type) {
      this.$bus.$emit("setBindingType", type);
    },
  },
};
</script>

<style>
</style>