<template>
  <div class="lv_flexalign_center act_layout">
    <div
      class="act_button"
      :class="{ actived: actived === 1 }"
      @click="
        actived = 1;
        $emit('layerShow', true);
      "
    >
      显示选中
    </div>
    <div
      class="act_button"
      :class="{ actived: actived === 2 }"
      @click="
        actived = 2;
        $emit('layerShow', false);
      "
    >
      显示全部
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      actived: 2,
    };
  },
};
</script>

<style>
</style>