import renderReal from '../../render/renderReal';
class MoveCommand {
    constructor(params, env) {
        this.params = params;
        this.env = env;
    }
    do() {
        for (let flowerObject of this.env.objects.flowers) {
            if (this.params.id === flowerObject.id) {
                if (this.params.end.x === flowerObject.modelMesh.flowerMesh.position.x) {
                    return;
                }
                flowerObject.clearReal();
                flowerObject.modelMesh.flowerMesh.position.set(this.params.end.x, this.params.end.y, this.params.end.z);
                flowerObject.updateModel();
                break;
            }
        }
        renderReal(this.env)
    }
    prev() {
        for (let flowerObject of this.env.objects.flowers) {
            if (this.params.id === flowerObject.id) {
                flowerObject.clearReal();
                flowerObject.modelMesh.flowerMesh.position.set(this.params.start.x, this.params.start.y, this.params.start.z);
                flowerObject.updateModel();
                break;
            }
        }
        renderReal(this.env)
    }
}


export default MoveCommand;