<template>
  <div>123</div>
</template>

<script>
export default {
  mounted() {
    
//https://cilisousuo.com/search?q=ipzz-075
//_ga=GA1.1.346830436.1691548685; _ym_uid=1691548688563866944; _ym_d=1691548688; _ym_isad=2; _ga_QZ4CLQPNW0=GS1.1.1691548684.1.1.1691549549.0.0.0

//https://www.h68g.com/cn/vl_star.php?s=abbds
//timezone=-480; __utma=112077661.1817926126.1691548433.1691548433.1691548433.1; __utmc=112077661; __utmz=112077661.1691548433.1.1.utmcsr=(direct)|utmccn=(direct)|utmcmd=(none); __utmt=1; __utmb=112077661.1.10.1691548433; __utma=112077661.1817926126.1691548433.1691548433.1691548433.1; __utmb=112077661.2.10.1691548433; __utmc=112077661; __utmz=112077661.1691548433.1.1.utmcsr=(direct)|utmccn=(direct)|utmcmd=(none); cf_clearance=Ftxl3E2d2fInEObdoK6ybudFb7fNr2A2NJBqRbu9jG0-1691548433-0-1-263a536a.b64f7d1e.55e3313e-0.2.1691548433; __qca=P0-167635501-1691548433805; over18=18

      this.$ajax
      .get("https://www.h68g.com/cn/vl_star.php?s=abbds")
      .then((res) => {
        console.log(res);
      });
  },
};
</script>

<style>
</style>