
class ClearCommand {
    constructor(type, env) {
        this.type = type;
        this.env = env
    }
    do() {
        if (this.type === 'flower' || !this.type) {
            for (let i = this.env.objects.flowers.length - 1; i > -1; i--) {
                this.env.objects.flowers[i].remove()
            }
        }
        if (this.type === 'container' || !this.type) {
            if (this.env.objects.container) {
                this.env.objects.container.remove();
            }
        }
    }

}

export default ClearCommand;