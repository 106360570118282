function loadImageToTexture(url) {
    return new Promise((resolve, reject) => {
        let image = new Image();
        image.setAttribute('crossOrigin', 'anonymous');
        image.src = url;
        image.onload = () => {
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            canvas.width = image.width;
            canvas.height = image.height;
            // ctx.shadowColor = 'rgba(0,0,0,0.4)';
            // ctx.shadowOffsetX = 1;
            // ctx.shadowOffsetY = 1;
            // ctx.shadowBlur = 4;
            ctx.drawImage(image, 0, 0);
            resolve({
                width: image.width,
                height: image.height,
                texture: new THREE.TextureLoader().load(canvas.toDataURL())
            });
        };
    })
}
export default loadImageToTexture;