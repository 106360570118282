import Vue from 'vue'
import VueRouter from 'vue-router'
import Bind from '@/views/bind/index.vue';
import Practice from '@/views/practice/index.vue';
import Share from '@/views/share/index.vue';
import Pysearch from '@/views/pysearch/index.vue';
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/practice' },
  { path: '/bind', component: Bind },
  { path: '/practice', component: Practice },
  { path: '/share', component: Share },
  { path: '/pysearch', component: Pysearch }
]

const router = new VueRouter({
  routes
})

export default router
