class CommandManager {
    constructor(env) {
        //环境
        this.env = env;
        //命令组
        this.commands = [];
        //重做组
        this.redoCommands = [];
    }
    execute(command) {
        command.do();
        this.commands.push(command);
        this.redoCommands = [];
        this.log(command);
    }

    revoke() {
        const command = this.commands.pop();
        command.prev();
        this.redoCommands.push(command);
        this.log(command);
    }

    redo() {
        const command = this.redoCommands.pop();
        command.do();
        this.commands.push(command);
        this.log(command);
    }

    log(command) {
        if (command.containChange) {
            if (this.env.listener['contain-change']) {
                this.env.listener['contain-change']();
            }

        }
        if (this.env.listener['step']) {
            this.env.listener['step']({
                revoke: this.commands.length,
                redo: this.redoCommands.length,
            });
        }
    }

}

export default CommandManager;