<template>
  <div class="lv_flexalign_center act_layout">
    <div>
      <div>横X轴</div>
      <van-stepper
        v-model="lateralAxis"
        :min="0"
        :max="97"
        @change="setAngle"
      />
    </div>
    <div>
      <div>竖Y轴</div>
      <van-stepper
        v-model="verticalAxis"
        :min="0"
        :max="360"
        @change="setAngle"
      />
    </div>
    <van-button type="default" @click="setDefault" size="small"
      >重置</van-button
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      defaultAngle: {
        x: 90,
        y: 180,
      },
      lateralAxis: 90,
      verticalAxis: 180,
    };
  },
  methods: {
    setAngle() {
      this.$bus.$emit("setAngle", {
        x: this.lateralAxis,
        y: this.verticalAxis,
      });
    },
    setDefault() {
      this.lateralAxis = this.defaultAngle.x;
      this.verticalAxis = this.defaultAngle.y;
        this.setAngle();
    },
  },
  created() {
    this.$bus.$on("setDefaultAngle", (val) => {
      this.defaultAngle.x = parseInt(val.x);
      this.defaultAngle.y = parseInt(val.y);
      this.lateralAxis = parseInt(val.x);
      this.verticalAxis = parseInt(val.y);
    });
  },
};
</script>

<style lang="scss" scoped>
.act_layout {
  justify-content: center;
  >*{
    +*{
      margin-left: rem(50);
    }
  }
}
</style>