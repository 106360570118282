import axios from 'axios'
function base64ToFile(base64, fileName) {
    var arr = base64.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    let theBlob = new Blob([u8arr], {
        type: mime,
    });
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
}

function uploadImage(item, ossData) {
    return new Promise(resolve => {
        let param = new FormData(),
            fileName = ossData.fileName + item.file.name // 创建form对象
        param.append('key', ossData.path + fileName) //存储在oss的文件路径
        param.append('OSSAccessKeyId', ossData.accessid) //accessKeyId
        param.append('policy', ossData.policy) //policy
        param.append('Signature', ossData.signature) //签名//如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
        param.append('file', item.file)
        param.append('success_action_status', 200) //成功后返回的操作码
        axios.post(ossData.host, param).then(res => {
            resolve({
                addr: ossData.path + fileName,
                info: item.info,
            })
        })
    })
}

function login(callback, that) {
    if (that.$route.query.code) {
        that.$ajax
            .post("/user/user/setCookie", that.$route.query.code, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then(() => {
                callback();
            });
    } else {
        callback();
    }
}


export { base64ToFile, uploadImage, login }