<template>
  <div class="act_layout">
    <div class="lv_flexalign_center">
      <van-slider
        v-model="size"
        @input="handleInput"
        :min="0.5"
        :max="2"
        :step="0.1"
        bar-height="4px"
        inactive-color="#999"
        active-color="#6e9f21"
      />
      <div class="slider_text">
        {{ size }}
      </div>
    </div>
    <div class="act_button" @click="remove">删除</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      size: 1,
      id: "",
    };
  },
  methods: {
    handleInput() {
      this.$bus.$emit("setSize", {
        id: this.id,
        value: this.size,
      });
    },
    remove() {
      this.$bus.$emit("remove", this.id);
    },
  },
  mounted() {
    this.$bus.$on("container-add", (val) => {
      this.id = val.id;
      this.size = val.params.size;
    });
  },
};
</script>
<style lang="scss" scoped>
.act_button {
  margin-top: rem(35);
}
</style>
