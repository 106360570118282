
class SetSizeCommand {
    constructor(params, env) {
        this.params = params;
        this.env = env
    }
    do() {
        if (this.params.id === 'jianshan') {
            let jsContainer = this.env.objects.jsContainer;
            jsContainer.scale.x = this.params.value;
            jsContainer.scale.y = this.params.value;
            jsContainer.scale.z = this.params.value;
            return;
        }
        let containerObject = this.env.objects.container;
        if (containerObject && (containerObject.id === this.params.id)) {
            containerObject.params.size = this.params.value;
            containerObject.updateModel();
        } else {
            for (let flowerObject of this.env.objects.flowers) {
                if (this.params.id === flowerObject.id) {
                    flowerObject.clearReal();
                    flowerObject.params.size = this.params.value;
                    flowerObject.updateModel();
                    break;
                }
            }
        }
    }
}

export default SetSizeCommand;