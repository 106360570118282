<template>
  <div class="top_controller">
    <div class="lv_flexalign_center step">
      <i
        class="iconfont icon-houtui-shi"
        :class="revoke ? '' : 'disable-icon'"
        @click="revokeFunc"
      ></i>
      <div></div>
      <i
        class="iconfont icon-qianjin-shi"
        :class="redo ? '' : 'disable-icon'"
        @click="redoFunc"
      ></i>
    </div>
    <div class="angle">
      <div class="lv_flexalign_center">
        <div>X</div>
        <div>{{ rotateX }}°</div>
      </div>
      <div class="lv_flexalign_center">
        <div>Y</div>
        <div>{{ rotateY }}°</div>
      </div>
      <div class="reflash lv_flex_center" @click="reset">
        <i class="iconfont icon-shuangxiangxuanzhuan" />
        刷新
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      revoke: 0, // 后退
      redo: 0, // 前进
      rotateX: 90,
      rotateY: 180,
    };
  },
  methods: {
    revokeFunc() {
      if (!this.revoke) return;
      this.$bus.$emit("revoke");
    },
    redoFunc() {
      if (!this.redo) return;
      this.$bus.$emit("redo");
    },
    reset() {
      this.rotateX = 90;
      this.rotateY = 180;
      this.$bus.$emit("setAngle", {
        x: this.rotateX,
        y: this.rotateY,
      });
    },
  },
  created() {
    this.$bus.$on("camera-change", (e) => {
      this.rotateX = parseInt(e.rotateX);
      this.rotateY = parseInt(e.rotateY);
    });

    this.$bus.$on("step-change", (e) => {
      this.revoke = e.revoke;
      this.redo = e.redo;
    });
  },
};
</script>

<style lang="scss" scoped>
.top_controller {
  position: fixed;
  top: rem(43);
  left: rem(27);
  .step {
    .iconfont {
      font-size: rem(40);
    }
    div {
      width: 1px;
      height: rem(31);
      background: #cdcdcd;
      margin: 0 rem(36);
    }
  }
  .angle {
    margin-top: rem(43);
    width: rem(90);
    background: #f7f7f7;
    border-radius: rem(14);
    border: 1px solid #dcdcdc;
    padding-top: rem(15);
    box-sizing: border-box;
    > .lv_flexalign_center {
      margin-bottom: rem(13);
      > div {
        &:first-child {
          width: rem(20);
          line-height: rem(20);
          background: #d0b35e;
          color: #fff;
          text-align: center;
          font-size: rem(16);
          margin-left: rem(16);
        }
        &:last-child {
          font-size: rem(18);
          margin-left: rem(3);
        }
      }
    }
    .reflash {
      height: rem(50);
      font-size: rem(20);
      margin: 0 rem(6);

      border-top: 1px solid #cdcdcd;
      i {
        font-size: rem(16);
        margin-right: rem(5);
      }
    }
  }
}
.disable-icon {
  color: rgb(146, 146, 146) !important;
  cursor: not-allowed !important;
}
</style>