<template>
  <div class="lv_flexalign_center act_layout">
    <div
      class="act_button"
      :class="{ actived: actived === 1 }"
      @click="
        actived = 1;
        open(true);
      "
    >
      开启
    </div>
    <div
      class="act_button"
      :class="{ actived: actived === 2 }"
      @click="
        actived = 2;
        open(false);
      "
    >
      关闭
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      actived: 2,
    };
  },
  methods: {
    open(status) {
      this.$bus.$emit("setTouchHelper", status); // 显示隐藏触点
    },
  },
};
</script>