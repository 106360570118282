
import {
    CAMERA_RADIUS
} from '../variable/constant';
function getPointsArr(params) {
    let { startPosition, sourcePosition, position, bindingType, bindingY } = params;
    let curve = null;
    let pointsArr = [];
    if (bindingType === 'westStyle') {
        //标准比例
        const standardRate = (0 - bindingY) / (position.y - bindingY);
        curve = new THREE.LineCurve3(
            new THREE.Vector3(position.x, position.y, position.z),
            new THREE.Vector3(standardRate * (position.x - startPosition.x) + startPosition.x, 0, standardRate * (position.z - startPosition.z) + startPosition.z)
        );
    } else {
        curve = new THREE.QuadraticBezierCurve3(
            new THREE.Vector3(position.x, position.y, position.z),
            new THREE.Vector3(startPosition.x, bindingY, startPosition.z),
            new THREE.Vector3((sourcePosition ? sourcePosition.x : position.x) * 0.05 + startPosition.x, 0, (sourcePosition ? sourcePosition.z : position.z) * 0.05 + startPosition.z)
        );
    }
    for (let point of curve.getPoints(128)) {
        pointsArr.push(point.x, point.y, point.z);
    }
    return pointsArr;
}

export default getPointsArr