import ajax from '../utils/ajax';
import { formatImage } from '../utils/index'
import loadImageToTexture from './loadImageToTexture'
let color = ['#86B755', '#000000', '#ffffff', '#eeff00'];
async function loadResource(id) {
    let sourceData = (await ajax.get('/admin/materialManage/getById', { params: { id } })).data;
    let sourceDataDetail = JSON.parse(sourceData.detail);

    if (sourceData.classifyId1 === 1) {
        for (let key in sourceDataDetail.angles) {
            sourceDataDetail.angles[key] = sourceDataDetail.angles[key].singles;
            const item = sourceDataDetail.angles[key];
            let promiseArr = [];
            for (let faceKey in item) {
                promiseArr.push(loadImageToTexture(formatImage(item[faceKey].url)));
            }
            let res = await Promise.all(promiseArr);
            for (let faceKey in res) {
                item[faceKey].texture = res[faceKey].texture;
                item[faceKey].width = res[faceKey].width;
                item[faceKey].height = res[faceKey].height;
            }
            if (item.length === 1) {
                item.push({
                    ...item[0],
                    turn: true
                })
            }

        }

    }

    sourceData.detail = null;
    return {
        ...sourceData,
        type: sourceData.classifyId1,
        detail: sourceDataDetail
    }
}


export default loadResource;