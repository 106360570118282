<template>
  <van-popup v-model="show" position="bottom">
    <div class="pop_main">
      <div class="type lv_flex">
        <div
          :class="{ type_actived: params.classifyId1 === 1 }"
          @click="
            params.classifyId1 = 1;
            typeReset();
          "
          v-if="!(replace && replace.classifyId1 !== 1)"
        >
          花卉
        </div>
        <div
          :class="{ type_actived: params.classifyId1 === 2 }"
          @click="
            params.classifyId1 = 2;
            typeReset();
          "
          v-if="!(replace && replace.classifyId1 !== 2)"
        >
          器皿
        </div>
      </div>

      <div v-if="params.classifyId1 === 1">
        <template v-if="$store.state.routeParams.type === 'practice'">
          <div class="flower_type">
            <div
              v-for="item in flower.types"
              :key="item.id"
              :class="{ flower_type_actived: params.classifyId3 === item.id }"
              @click="
                params.classifyId3 = item.id;
                reset();
              "
            >
              {{ item.name }}
            </div>
          </div>
          <div class="lv_flex flower_other">
            <div class="lv_flex_center" @click="flower.otherActived = 1">
              <i
                class="iconfont icon-yanse"
                v-if="activedColor.name === '颜色'"
              />
              <div
                v-else
                :style="{ background: activedColor.color }"
                class="active_color"
              ></div>
              {{ activedColor.name }}
            </div>
            <div class="lv_flex_center" @click="flower.otherActived = 2">
              <i class="iconfont icon-kaifang" />{{
                params.floweringStage ? params.floweringStage + "月" : "花季"
              }}
            </div>
            <div
              class="lv_flex_center"
              :class="{ actived_like: params.queryLikeMaterial }"
              @click="
                params.queryLikeMaterial = !params.queryLikeMaterial;
                reset();
              "
            >
              <i class="iconfont icon-xingxing" />常用
            </div>
            <div
              class="lv_flex_center"
              :class="{ actived_like: params.name }"
              @click="flower.otherActived = 4"
            >
              <i class="iconfont icon-suofang" />搜索
            </div>
          </div>
        </template>
        <div v-if="flower.otherActived === 1" class="color_list">
          <div
            v-for="item in flower.colors"
            :key="item.id"
            :style="{ background: item.color }"
            @click="
              params.colorClassifyId = item.id;
              reset();
            "
          ></div>
        </div>

        <div v-if="flower.otherActived === 2" class="month_list">
          <div
            @click="
              params.floweringStage = null;
              reset();
            "
          >
            取消
          </div>
          <div
            v-for="item in 12"
            :key="item"
            @click="
              params.floweringStage = item + '';
              reset();
            "
          >
            {{ item }}月
          </div>
        </div>

        <div v-if="flower.otherActived === 4" class="mb_20">
          <van-search
            v-model="params.name"
            placeholder="请输入搜索关键词"
            show-action
            background="#F4F6EE"
            @search="reset()"
          >
            <template #action>
              <div @click="reset()">搜索</div>
            </template>
          </van-search>
        </div>
      </div>
      <div v-if="params.classifyId1 === 2">
        <template v-if="$store.state.routeParams.type === 'practice'">
          <div class="flower_type">
            <div
              v-for="item in container.types"
              :key="item.id"
              :class="{ flower_type_actived: params.classifyId2 === item.id }"
              @click="
                params.classifyId2 = item.id;
                reset();
              "
            >
              {{ item.name }}
            </div>
          </div>

          <div class="lv_flex flower_other">
            <div
              class="lv_flex_center"
              :class="{ actived_like: params.queryLikeMaterial }"
              @click="
                params.queryLikeMaterial = !params.queryLikeMaterial;
                reset();
              "
            >
              <i class="iconfont icon-xingxing" />常用
            </div>
            <div
              class="lv_flex_center"
              :class="{ actived_like: params.name }"
              @click="container.otherActived = 4"
            >
              <i class="iconfont icon-suofang" />搜索
            </div>
          </div>
        </template>
        <div v-if="container.otherActived === 4" class="mb_20">
          <van-search
            v-model="params.name"
            placeholder="请输入搜索关键词"
            show-action
            background="#F4F6EE"
            @search="reset()"
          >
            <template #action>
              <div @click="reset()">搜索</div>
            </template>
          </van-search>
        </div>
      </div>
      <div class="list">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          class="van_list"
          @load="loadData"
        >
          <div v-for="(item, index) in list" :key="index" class="item">
            <div
              class="flower"
              :style="{
                backgroundImage: `url(https://lvshuhualv.oss-cn-hangzhou.aliyuncs.com/${item.coverImage})`,
              }"
              @click="addItem(item.id || item.materialId)"
            ></div>
            <div class="name">{{ item.name }}</div>
            <i
              class="iconfont icon-xingxing"
              :class="{ 'icon-xingxing1': item.likeMaterial }"
              @click.self="changeLike(item)"
              v-if="$store.state.routeParams.type === 'practice'"
            />
          </div>
        </van-list>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      replace: null,
      show: false,
      typeActived: 0,
      loading: false,
      finished: false,
      flower: {
        types: [],
        colors: [],
        months: [],
        otherActived: -1,
      },
      container: {
        types: [],
        otherActived: -1,
      },
      params: {
        classifyId1: 1,
        classifyId2: null,
        classifyId3: null,
        colorClassifyId: null,
        name: "",
        floweringStage: "",
        pageIndex: 0,
        pageSize: 12,
        queryLikeMaterial: false,
      },
      list: [],
    };
  },
  created() {
    this.getLabelList();
  },
  methods: {
    async changeLike(item) {
      if (item.likeMaterial) {
        await this.$ajax.get("/material/deleteLikeMaterial", {
          params: { materialId: item.id },
        });
      } else {
        await this.$ajax.post("/material/saveLikeMaterial", {
          materialId: item.id,
        });
      }
      Toast("操作成功");
      item.likeMaterial = !item.likeMaterial;
    },
    open(replace) {
      this.params.classifyId1 = (replace && replace.classifyId1) || 1;
      this.replace = replace || null;
      if (this.list.length) {
        this.typeReset();
      }
      this.show = true;
    },
    addItem(id) {
      if (this.$store.state.routeParams.mode === "examine") {
        return;
      }
      if (this.replace && this.replace.id) {
        this.$bus.$emit("replace", {
          id: this.replace.id,
          resourceId: id,
        });
        this.show = false;
      } else {
        //弯曲
        this.$bus.$emit("addItem", id);
      }
    },
    typeReset() {
      this.params.classifyId2 = null;
      this.params.classifyId3 = null;
      this.params.colorClassifyId = null;
      this.params.name = null;
      this.params.queryLikeMaterial = false;
      this.reset();
    },
    reset() {
      this.params.pageIndex = 1;
      this.list = [];
      this.finished = false;
      this.loading = true;
      this.flower.otherActived = -1;
      this.container.otherActived = -1;
      this.getList();
    },
    loadData() {
      this.params.pageIndex++;
      this.getList();
    },
    getLabelList() {
      this.$ajax.post("admin/classifyManage/queryAll").then((res) => {
        const resData = res.data;
        this.flower.types = [
          {
            name: "全部",
            id: null,
            parentId: null,
          },
          ...resData[0].child[0].child,
          ...resData[0].child[1].child,
        ];
        this.flower.colors = [
          {
            name: "无",
            id: null,
            color:
              "url(https://lvshuhualv.oss-cn-hangzhou.aliyuncs.com/static/masaike.png)",
          },
          ...resData[3].child,
        ];

        this.container.types = [
          {
            name: "全部",
            id: null,
            parentId: null,
          },
          ...resData[1].child,
        ];
      });
    },
    getList() {
      if (this.$store.state.routeParams.type !== "practice") {
        this.list =
          this.$store.state.limitMaterials[
            this.params.classifyId1 - 1
          ].useMaterials;
        this.loading = false;
        return;
      }

      if (this.params.classifyId1 === 1) {
        if (this.params.classifyId3) {
          this.params.classifyId2 = this.flower.types.find((item) => {
            return item.id === this.params.classifyId3;
          }).parentId;
        } else {
          this.params.classifyId2 = null;
        }
      }
      this.$ajax.post("/material/queryFlowerList", this.params).then((res) => {
        this.list = this.list.concat(res.list);
        this.loading = false;
        // 数据全部加载完成
        if (this.list.length >= res.total) {
          this.finished = true;
        }
      });
    },
  },
  computed: {
    activedColor() {
      return !this.params.colorClassifyId
        ? {
            name: "颜色",
          }
        : this.flower.colors.find((item) => {
            return item.id === this.params.colorClassifyId;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.pop_main {
  background: #f7f7f7;
  padding: rem(14) rem(30) rem(20) rem(30);
  .type {
    font-size: rem(28);
    line-height: rem(34);
    padding: 0 rem(20);
    div {
      padding: rem(10) 0;
      + div {
        margin-left: rem(134);
      }
    }
    .type_actived {
      color: #8bb229;
      font-weight: bold;
      border-bottom: 2px solid #8bb229;
    }
  }
  .flower_type {
    padding: rem(31) 0 rem(26) 0;
    white-space: nowrap;
    overflow-x: auto;
    > div {
      font-size: rem(24);
      line-height: rem(33);
      padding: rem(8) rem(15);
      width: fit-content;
      border-radius: rem(6);
      background: #e8efd5;
      display: inline-block;
      + div {
        margin-left: rem(15);
      }
    }
    .flower_type_actived {
      background: #8bb229;
      color: #fff;
    }
  }
  .flower_other {
    margin-bottom: rem(30);
    > div {
      flex: 1;
      text-align: center;
      background: #fff;
      border-radius: rem(6);
      border: 1px solid #e3e3e3;
      height: rem(50);
      + div {
        margin-left: rem(16);
      }
    }
    i {
      margin-right: 2px;
    }
  }
  .color_list {
    > div {
      width: rem(38);
      height: rem(38);
      border-radius: rem(9);
      display: inline-block;
      margin-left: rem(26);
      margin-bottom: rem(25);
      &:nth-child(11n + 1) {
        margin-left: 0;
      }
    }
  }
  .active_color {
    width: rem(35);
    height: rem(35);
    border-radius: rem(9);
    margin-right: 5px;
  }
  .month_list {
    > div {
      width: rem(85);
      line-height: rem(56);
      text-align: center;
      display: inline-block;
      border-radius: rem(14);
      border: 1px solid #dcdfe6;
      margin-left: rem(10);
      margin-bottom: rem(25);
      &:nth-child(7n + 1) {
        margin-left: 0;
      }
    }
  }
  .actived_like {
    color: #df8c07;
  }
}
.list {
  height: rem(320);
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: rem(20);
  .item {
    width: rem(144);
    margin-left: rem(37);
    display: inline-block;
    margin-bottom: rem(30);
    position: relative;
    &:nth-child(4n + 1) {
      margin-left: 0;
    }
    i {
      position: absolute;
      right: rem(20);
      top: rem(20);
    }
  }
  .flower {
    width: rem(144);
    height: rem(144);
    border-radius: 50%;
    border: 1px solid rgb(219, 219, 219);
    margin-bottom: rem(7);
    background-size: 100% 100%;
  }
  .name {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.mb_20 {
  margin-bottom: rem(20);
}
</style>